import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'CHAMADA_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}

export function setAguardando(aguardando) {
    return {
        type: 'CHAMADA_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function initForm(registro = {}) {
    return [
        initialize('chamadaForm', registro)
    ];
}

export function getLista() {
	const request = axios.get(`${consts.API_URL}/chamada`);
	return {
		type: 'CHAMADA_LISTADO',
		payload: request
	};
}

export function salvar(registro) {
    return (dispatch, getState) => {
		dispatch(setAguardando(true));
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/chamada`, {
				...registro,
				data_chamada: DateFormat.formatarDataTelaParaSql(registro.data_chamada),
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(getListaItem(resp.data));
				dispatch(setModoTela('cadastro', {
					...resp.data,
					data_chamada: DateFormat.formatarDataSqlParaTela(resp.data.data_chamada)
				}));
				dispatch(initForm({
					...resp.data,
					data_chamada: DateFormat.formatarDataSqlParaTela(resp.data.data_chamada)
				}));
				dispatch(setAguardando(false));
			}).catch(e => {
				console.log(e);
				setErroAPI(e);
				dispatch(setAguardando(false));
			});
		} else {
			axios.put(`${consts.API_URL}/chamada`, {
				...registro,
				data_chamada: DateFormat.formatarDataTelaParaSql(registro.data_chamada),
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
				dispatch(setAguardando(false));
			}).catch(e => {
				setErroAPI(e);
				dispatch(setAguardando(false));
			});
		}
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/chamada?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaCliente() {
    const request = axios.get(`${consts.API_URL}/cliente/listarSelect`);
    return {
        type: 'CHAMADA_CLIENTE_SELECT_LISTADO',
        payload: request
    };
}

export function getListaUnidade() {
    const request = axios.get(`${consts.API_URL}/unidade/listarSelect`);
    return {
        type: 'CHAMADA_UNIDADE_SELECT_LISTADO',
        payload: request
    };
}

export function getListaSetor() {
    const request = axios.get(`${consts.API_URL}/setor/listarSelect`);
    return {
        type: 'CHAMADA_SETOR_SELECT_LISTADO',
        payload: request
    };
}

//Itens

export function getListaItem(chamada) {
	const request = axios.get(`${consts.API_URL}/chamadaItem?id_chamada=${chamada.id || 0}`);
	return {
		type: 'CHAMADA_ITEM_LISTADO',
		payload: request
	};
}

export function salvarItem(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
			axios.post(`${consts.API_URL}/chamadaItem`, {
				...registro,
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				dispatch(getListaItem({ id: registro.id_chamada }));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/chamadaItem`, {
				...registro,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				dispatch(getListaItem({ id: registro.id_chamada }));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluirItem(registro) {
    return (dispatch, getState) => {
		if (window.confirm('Deseja realmente remover da chamada')) {
			axios.delete(`${consts.API_URL}/chamadaItem?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`, {
				...registro,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				dispatch(getListaItem({ id: registro.id_chamada }));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function getListaMensal(filtro) {
	const request = axios.get(`${consts.API_URL}/chamada/mensal?
		id_cliente=${filtro.id_cliente}
		&id_unidade=${filtro.id_unidade}
		&id_setor=${filtro.id_setor}
		&ano=${filtro.ano}
		&mes=${filtro.mes}
	`);
	return {
		type: 'CHAMADA_MENSAL_LISTADO',
		payload: request
	};
}

export function imprimirMensal(registro) {

	return async (dispatch, getState) => {

		axios.post(`${consts.API_URL}/chamada/imprimirMensal`, {
			nome: registro.nome,
			ano: registro.ano,
			mes: registro.mes,
			lista: registro.lista.map(item => {

				const dias = item.dias.filter(dia => dia.sigla == 'P' || dia.sigla == 'F' || dia.sigla == 'A').length;
				const presencas = item.dias.filter(dia => dia.sigla == 'P').length;
				const faltas = item.dias.filter(dia => dia.sigla == 'F').length;
				const ausencias = item.dias.filter(dia => dia.sigla == 'A').length;

				return {
					nome: item.nome,
					dias: dias,
					presencas: presencas,
					faltas: faltas,
					ausencias: ausencias,
					percentual: `${FormatUtils.formatarValorTela(100 - (dias > 0 ? (faltas * 100) / dias : 0), 0)}%`
				};
			}),
			listaParticipacao: registro.listaParticipacao.map(item => ({
				nome_unidade: item.nome_unidade,
				nome_setor: item.nome_setor,
				colaboradores: `${FormatUtils.formatarValorTela(item.colaboradores, 0)}`,
				participantes: `${FormatUtils.formatarValorTela(item.participantes, 0)}`,
				nao_participam: `${FormatUtils.formatarValorTela(item.nao_participam, 0)}`,
				ausentes: `${FormatUtils.formatarValorTela(item.ausentes, 0)}`,
				participacao: `${FormatUtils.formatarValorTela(item.participacao, 0)}%`,
				assiduidade: `${FormatUtils.formatarValorTela(item.assiduidade, 0)}%`
			}))
		}).then(resp => {
			window.open(`${consts.URL}/${resp.data}`, '_blank');
		}).catch(ex => {
			console.log(ex);
		});

	}
}

export function getListaMensalParticipacao(filtro) {
	const request = axios.get(`${consts.API_URL}/chamada/mensalParticipacao?
		id_cliente=${filtro.id_cliente}
		${filtro.id_unidade ? `&id_unidade=${filtro.id_unidade}` : ''}
		${filtro.id_setor ? `&id_setor=${filtro.id_setor}` : ''}
		&ano=${filtro.ano}
		&mes=${filtro.mes}
	`);
	return {
		type: 'CHAMADA_MENSAL_PARTICIPACAO_LISTADO',
		payload: request
	};
}

export function imprimirMensalParticipacao(registro) {

	return async (dispatch, getState) => {

		axios.post(`${consts.API_URL}/chamada/imprimirParticipacao`, {
			nome_cliente: registro.nome_cliente,
			ano: registro.ano,
			mes: registro.mes,
			lista: registro.lista.map(item => ({
				nome_unidade: item.nome_unidade,
				nome_setor: item.nome_setor,
				colaboradores: `${FormatUtils.formatarValorTela(item.colaboradores, 0)}`,
				participantes: `${FormatUtils.formatarValorTela(item.participantes, 0)}`,
				nao_participam: `${FormatUtils.formatarValorTela(item.nao_participam, 0)}`,
				ausentes: `${FormatUtils.formatarValorTela(item.ausentes, 0)}`,
				participacao: `${FormatUtils.formatarValorTela(item.participacao, 0)}%`,
				assiduidade: `${FormatUtils.formatarValorTela(item.assiduidade, 0)}%`
			}))
		}).then(resp => {
			window.open(`${consts.URL}/${resp.data}`, '_blank');
		}).catch(ex => {
			console.log(ex);
		});

	}
}
